<template>
  <div class="login">
    <div id="wrapper" class="clearfix">
      <div class="content-wrap" v-if="user.billed === 0">
        <div style="text-align: center">
          Vous n'avez malheuresement pas accès à cette page ! <br />Pour accéder
          à la recherche vous devez être abonné.
        </div>
      </div>
      <div class="content-wrap" v-else>
        <h1 class="main-color" style="text-align: center">
          Rechercher une formation
        </h1>
        <div class="form-form">
          <v-form>
            <v-container>
              <v-row>
                <v-col style="padding-top: 2rem" cols="12" md="6" sm="12">
                  <v-autocomplete
                    style="padding-top: 4px"
                    v-model="departement"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    label="Par départements"
                    :items="dep"
                    item-text="name"
                    item-value="code"
                  ></v-autocomplete>
                </v-col>
                <v-col style="padding-top: 2rem" cols="12" md="6" sm="12">
                  <v-text-field
                    label="Réserver ... Place(s)"
                    v-model="place"
                    hide-details
                    single-line
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col style="padding-top: 2rem" cols="12" md="6" sm="12">
                  <v-autocomplete
                    style="padding-top: 4px"
                    v-model="domain"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    label="Par domaine d'intervention"
                    :items="domainsListe"
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col style="padding-top: 2rem" cols="12" sm="12" md="6">
                  <v-menu
                    v-model="menu1"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date"
                        label="A partir du ... (AAAA-MM-JJ)"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      @input="menu1 = false"
                      locale="fr-FR"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="4" sm="12"> </v-col>
                <v-col style="padding-top: 2rem" cols="12" md="4" sm="12">
                  <v-btn
                    block
                    color="#00897f"
                    dark
                    :to="{
                      name: 'result-training',
                      params: {
                        Sdomain: domain,
                        Sdepartement: departement,
                        Splace: place,
                        Sdate: date,
                      },
                    }"
                  >
                    Rechercher
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4" sm="12"> </v-col>
              </v-row>
            </v-container>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SearchTraining",
  data() {
    return {
      domain: null,
      departement: null,
      place: "",
      menu1: false,
      date: null,
    };
  },
  computed: {
    ...mapGetters("meta_data", { domainsListe: "getDomainsListe" }),
    ...mapGetters("meta_data", { dep: "getDepartements" }),
    ...mapGetters("user", { user: "getUser" }),
  },
};
</script>

<style scoped>
.login {
  padding: 50px;
}
.form-form {
  display: flex;
  justify-content: center;
}
</style>
