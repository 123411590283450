<template>
  <v-container>
    <section>
      <h1 style="text-align: center" class="main-color">
        Les formateurs disponibles
      </h1>
      <v-container class="search-header clearfix" fluid>
        <h3>Vos filtres</h3>
        <v-divider />
        <div>
          <v-row>
            <v-col style="padding-top: 2rem" cols="12" md="3" sm="12">
              <v-autocomplete
                style="padding-top: 4px"
                v-model="domain"
                chips
                clearable
                deletable-chips
                multiple
                label="Par domaine d'intervention"
                :items="domainsListe"
                item-text="name"
                item-value="id"
              ></v-autocomplete>
            </v-col>
            <v-col style="padding-top: 2rem" cols="12" md="3" sm="12">
              <v-autocomplete
                style="padding-top: 4px"
                v-model="departement"
                chips
                clearable
                deletable-chips
                multiple
                label="Par départements"
                :items="dep"
                item-text="name"
                item-value="code"
              ></v-autocomplete>
            </v-col>
            <v-col style="padding-top: 2rem" cols="12" md="3" sm="12">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="A partir du ... (AAAA-MM-JJ)"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu1 = false"
                  locale="fr-FR"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col style="padding-top: 2rem" cols="12" md="3" sm="12">
              <v-text-field label="Par nom" v-model="name"></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="12"> </v-col>
            <v-col style="" cols="12" md="4" sm="12">
              <v-btn block color="#00897f" dark @click="Search">
                Rechercher
              </v-btn>
            </v-col>
            <v-col cols="12" md="4" sm="12">
              <v-select
                class="shrink"
                prefix="Nombre de résultats par page"
                filled
                rounded
                dense
                :items="size"
                v-model="limit"
                :item-value="limit"
              >
              </v-select
            ></v-col>
          </v-row>
        </div>
        <v-divider />
        <!-- <div v-if="false">
          <h4>Rechercher dans vos résultats</h4>
          <v-text-field label="Recherche" v-model="search"></v-text-field>
          <v-divider />
        </div> -->
      </v-container>
    </section>

    <section v-if="formers.size_max > 0">
      <v-row justify="center" v-if="formers.size_max > length">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              @input="Search"
              v-model="page"
              class="my-4"
              :length="length"
              :total-visible="7"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="former in formers.formatives"
          :key="former.id"
          cols="12"
          md="6"
          sm="12"
        >
          <v-card elevation="2" height="200px" class="overflow-y-auto">
            <div class="d-flex flex-no-wrap ">
              <v-avatar class="ma-3" size="125" tile>
                <v-img src="@/assets/logo.png"></v-img>
              </v-avatar>
              <div>
                <v-card-title class="text-h5">
                  {{ former.first_name }} {{ former.last_name }}</v-card-title
                >

                <v-card-text>
                  {{ former.desc }}
                </v-card-text>

                <v-card-actions style="">
                  <v-btn
                    class="ml-2 mt-5"
                    outlined
                    right
                    small
                    :to="{
                      name: 'former-id',
                      params: {
                        id: former.id,
                      },
                    }"
                  >
                    Vers le profil
                  </v-btn>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="formers.size_max > length">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              @input="Search"
              v-model="page"
              class="my-4"
              :length="length"
              :total-visible="7"
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </section>
    <section v-else>
      <div>
        <p>Il n’y a pas de formateur répondant à vos critères</p>
        <p></p>
      </div>
    </section>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ResultFormer",
  props: {
    Sdomain: {
      required: false,
    },
    Snom: {
      required: false,
    },
    Sdate: {
      required: false,
    },
    Sdepartement: {
      required: false,
    },
  },
  created() {
    this.departement = this.Sdepartement;
    this.domain = this.Sdomain;
    this.name = this.Snom;
    this.date = this.Sdate;
    let request = "limit=20&offset=0";
    if (this.Sdepartement) {
      request = request + "&department=" + this.Sdepartement.toString();
    }
    if (this.Sdomain) {
      request = request + "&domain=" + this.Sdomain.toString();
    }
    if (this.Snom) {
      request = request + "&name=" + this.Snom;
    }
    this.fetchFormative(request);
  },
  data() {
    return {
      page: 1,
      limit: 20,
      size: [10, 15, 20],
      search: null,
      domain: null,
      departement: null,
      name: "",
      menu1: false,
      date: null,
      fakeData: {
        title: "Nom du formateur",
        text: "Description du formateur",
        img: "@/assets/logo.png",
      },
    };
  },
  computed: {
    ...mapGetters("meta_data", { dep: "getDepartements" }),
    ...mapGetters("meta_data", { domainsListe: "getDomainsListe" }),
    ...mapGetters("former", { formers: "getFormerList" }),
    length() {
      return Math.ceil(this.formers.size_max / this.limit);
    },
    offset() {
      return this.page * this.limit - this.limit;
    },
  },
  methods: {
    Search() {
      let request = "limit=" + this.limit + "&offset=" + (this.page - 1);
      if (this.departement) {
        request = request + "&department=" + this.departement.toString();
      }
      if (this.domain) {
        request = request + "&domain=" + this.domain.toString();
      }
      if (this.name) {
        request = request + "&name=" + this.name;
      }
      this.fetchFormative(request);
    },
    ...mapActions("former", ["fetchFormative"]),
  },
};
</script>

<style scoped>
.search-header {
  text-align: center;
  padding-top: 2rem;
  width: 1000%;
}
</style>
