<template>
  <v-container>
    <section>
      <div class="content-wrap">
        <div>
          <h1 style="text-align: center" class="main-color">
            Les annonces
          </h1>
          <v-container>
            <v-divider />
            <div>
              <v-row>
                <v-col
                  style="padding-top: 2rem"
                  cols="12"
                  md="4"
                  sm="12"
                  v-if="domainsListe"
                >
                  <v-autocomplete
                    style="padding-top: 4px"
                    v-model="domain"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    label="Par domaine d'intervention"
                    :items="domainsListe"
                    item-text="name"
                    item-value="id"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  style="padding-top: 2rem"
                  cols="12"
                  md="4"
                  sm="12"
                  v-if="dep"
                >
                  <v-autocomplete
                    style="padding-top: 4px"
                    v-model="departement"
                    chips
                    clearable
                    deletable-chips
                    multiple
                    label="Par départements"
                    :items="dep"
                    item-text="name"
                    item-value="code"
                  ></v-autocomplete>
                </v-col>
                <v-col style="padding-top: 35px" cols="12" md="4" sm="12">
                  <v-btn block color="#00897f" dark @click="ReSearch">
                    Rechercher
                  </v-btn>
                </v-col>
                <v-col cols="12" md="4" sm="12"> </v-col>
                <v-col cols="12" md="4" sm="12">
                  <v-select
                    class="shrink"
                    prefix="Nombre de résultats par page"
                    filled
                    rounded
                    dense
                    :items="size"
                    v-model="limit"
                  >
                  </v-select
                ></v-col>
              </v-row>
            </div>
            <v-divider />
          </v-container>
        </div>
      </div>
    </section>

    <section v-if="announcement">
      <v-row v-if="announcement.size_max > length">
        <v-col cols="12">
          <v-pagination
            @input="Search"
            v-model="page"
            :length="length"
            :total-visible="7"
            circle
          ></v-pagination>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="annonce in announcement.events"
          :key="annonce.id"
          cols="12"
          md="6"
          sm="12"
        >
          <v-card
            elevation="2"
            height="200px"
            class="overflow-y-auto"
            @click="
              $router.push({
                name: 'detail-annonce',
                params: {
                  id: annonce.id,
                },
              })
            "
          >
            <div class="d-flex flex-no-wrap ">
              <v-avatar class="ma-3" size="125" tile>
                <v-img
                  v-if="annonce.file_name"
                  :src="
                    'https://api.just-form.fr/uploads/image/' +
                      annonce.file_name
                  "
                ></v-img>
              </v-avatar>
              <div>
                {{ annonce.file_name }}
                <v-card-title class="text-h5">
                  {{ annonce.title }}
                </v-card-title>

                <v-card-text>
                  {{ annonce.desc }}
                </v-card-text>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="announcement.size_max > length">
        <v-col cols="8">
          <v-container class="max-width">
            <v-pagination
              @input="Search"
              v-model="page"
              class="my-4"
              :length="length"
              :total-visible="7"
              circle
            ></v-pagination>
          </v-container>
        </v-col>
      </v-row>
    </section>
    <section v-else>
      <div>
        <p>Aucun résultat ne correspond à votre Recherche</p>
        <p></p>
      </div>
    </section>
  </v-container>
</template>

<script>
import * as dayjs from "dayjs";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ResultOffers",
  props: {
    Sdepartement: {
      required: false,
    },
  },
  mounted() {
    console.log(typeof this.$route.query.departement);
    if (
      typeof this.$route.query.departement !== "undefined" &&
      typeof this.$route.query.departement !== "number" &&
      this.$route.query.departement &&
      this.$route.query.departement.includes(",")
    ) {
      this.departement = this.$route.query.departement.split(",").map(String);
    } else if (
      typeof this.$route.query.departement !== "undefined" &&
      this.$route.query.departement
    ) {
      this.departement = this.$route.query.departement.toString();
    }

    if (
      typeof this.$route.query.domain !== "undefined" &&
      this.$route.query.domain &&
      this.$route.query.domain.includes(",")
    ) {
      this.domain = this.$route.query.domain.split(",").map(Number);
    } else if (
      typeof this.$route.query.domain !== "undefined" &&
      this.$route.query.domain
    ) {
      this.domain = this.$route.query.domain.split(",").map(Number);
    }
    let request = "limit=20&offset=0";
    if (this.departement) {
      request = request + "&department=" + this.departement.toString();
    }
    if (this.domain) {
      request = request + "&department=" + this.domain.toString();
    }
    console.log("request", request);
    this.fetchAnnouncement(request);
  },
  data() {
    return {
      page: 1,
      limit: 20,
      size: [10, 15, 20],
      search: null,
      domain: null,
      departement: null,
      place: null,
      menu1: false,
      date: null,
      fakeData: {
        title: "Nom du formateur",
        text: "Description du formateur",
        img: "@/assets/logo.png",
      },
    };
  },
  computed: {
    ...mapGetters("meta_data", { dep: "getDepartements" }),
    ...mapGetters("meta_data", { domainsListe: "getDomainsListe" }),
    ...mapGetters("announcement", { announcement: "getAnnouncementList" }),
    length() {
      return Math.ceil(this.announcement.size_max / this.limit);
    },
    offset() {
      return this.page * this.limit - this.limit;
    },
  },
  methods: {
    ReSearch() {
      let departement = null;
      let domain = null;
      if (this.departement) {
        departement = this.departement.toString();
      }
      if (this.domain) {
        domain = this.domain.toString();
      }
      this.$router.replace({
        name: "petites-annonces-result",
        query: {
          departement: departement,
          domain: domain,
        },
      });
      console.log("ok");
      this.Search();
    },
    Search() {
      let request = "limit=" + this.limit + "&offset=" + (this.page - 1);
      if (this.departement) {
        request = request + "&department=" + this.departement.toString();
      }
      if (this.domain) {
        request = request + "&domain=" + this.domain.toString();
      }
      this.fetchAnnouncement(request);
    },
    dateFrom(date) {
      return dayjs(date, "YYYY--MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    ...mapActions("announcement", ["fetchAnnouncement"]),
  },
};
</script>

<style scoped>
.search-header {
  text-align: center;
  padding-top: 2rem;
  width: 1000%;
}
</style>
