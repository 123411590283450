<template>
  <div style="padding: 50px;">
    <div id="wrapper" class="clearfix">
      <div class="single-product">
        <div class="product" v-if="announcement">
          <div class="row gutter-40">
            <div class="col-md-5">
              <!-- Product Single - Gallery
									============================================= -->
              <div class="product-image">
                <v-img
                  v-if="announcement.file_name"
                  :src="
                    'https://api.just-form.fr/uploads/image/' +
                      announcement.file_name
                  "
                ></v-img>
              </div>
              <!-- Product Single - Gallery End -->
            </div>

            <div class="col-md-5 product-desc">
              <div class="d-flex align-items-center justify-content-between">
                <!-- Product Single - Title
										============================================= -->
                <div class="product-title">
                  <h3>{{ announcement.title }}</h3>
                </div>
                <!-- Product Single - Title End -->
              </div>

              <div class="line"></div>

              <!-- Product Single - Short Description
									============================================= -->
              <div>
                <p>
                  {{ announcement.desc }}
                </p>
              </div>

              <!-- Product Single - Short Description End -->
            </div>

            <div class="col-md-2">
              <div class="divider divider-center">
                <i class="icon-circle-blank"></i>
              </div>

              <div class="feature-box fbox-plain fbox-dark fbox-sm">
                <div class="fbox-icon">
                  <v-icon>
                    mdi-email
                  </v-icon>
                </div>
                <div class="fbox-content fbox-content-sm">
                  <h3>Email</h3>
                  <p class="mt-0">{{ announcement.creator.email }}</p>
                </div>
              </div>

              <div class="feature-box fbox-plain fbox-dark fbox-sm mt-4">
                <v-icon>
                  mdi-phone
                </v-icon>
                <div class="fbox-content fbox-content-sm">
                  <h3>Téléphone</h3>
                  <p class="mt-0">{{ announcement.creator.phone }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "DetailOffer",
  mounted() {
    this.fetchAnnouncementById(this.$route.params.id);
  },
  computed: {
    ...mapGetters("announcement", { announcement: "getAnnouncement" }),
  },
  methods: {
    ...mapActions("announcement", ["fetchAnnouncementById"]),
  },
};
</script>
