<template>
  <div class="login">
    <div id="wrapper" class="clearfix">
      <div class="content-wrap" v-if="user">
        <div style="text-align: center">
          <h1 class="main-color" style="text-align: center">
            Déposer une annonce
          </h1>
          <h5 style="text-align: center">
            Vous trouverez vos annonces dans l'onglet mon compte
          </h5>
          <v-form style="padding: 5px 2em 0 2em">
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="create.title"
                  label="Titre de l'annonce"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12" sm="12" v-if="domainsListe">
                <v-autocomplete
                  style="padding-top: 4px"
                  v-model="create.domain"
                  chips
                  clearable
                  deletable-chips
                  multiple
                  label="Domaine d'intervention"
                  :items="domainsListe"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  v-if="departementsListe"
                  v-model="create.department_id"
                  chips
                  clearable
                  deletable-chips
                  label="Département"
                  :items="departementsListe"
                  item-text="name"
                  item-value="id"
                  dense
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="create.desc"
                  label="Description"
                  counter
                  full-width
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12">
                <input
                  @change="handleImage"
                  class="custom-input"
                  type="file"
                  accept="image/*"
                />
                <img style="" :src="create.path_photo" alt="" />
              </v-col>
              <v-col cols="12" sm="12">
                <v-btn color="#00897F" text elevation="1" @click="Register"
                  >Sauvegarder</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "Create",
  data: () => ({
    selectedTabs: [],
    create: {
      title: null,
      domain: null,
      department_id: null,
      desc: null,
      path_photo: "",
      mail: "",
      phone: "",
    },
  }),
  computed: {
    ...mapGetters("meta_data", { domainsListe: "getDomainsListe" }),
    ...mapGetters("meta_data", { departementsListe: "getDepartements" }),
    ...mapGetters("announcement", { announcement: "getMyAnnouncementList" }),
    ...mapGetters("user", { user: "getUser" }),
  },
  methods: {
    handleImage(e) {
      const selectedImage = e.target.files[0];
      this.createBase64Image(selectedImage);
    },
    createBase64Image(fileObject) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.create.path_photo = e.target.result;
      };
      reader.readAsDataURL(fileObject);
    },
    Register() {
      this.addAnnoucement(this.create);
    },
    ...mapActions("announcement", ["addAnnoucement"]),
  },
};
</script>

<style scoped>
.login {
  padding: 50px;
}
.form-form {
  display: flex;
  justify-content: center;
}
</style>
